<template>
  <div class="page page-xprogrammatic-all-jobs">

    <Side
      component="forms/job/compact/FormJobCompact"
      :ids="(selected || []).map(v => v.id)"
      :props="{campaign_id: campaignId}"
      :value="showEdit"
      @close="closeEdit"
      @create="loadItems"
      @update="loadItems"
      @remove="removeItems"
    />

    <Side
      component="forms/job_publication/compact/FormJobPublicationCompact"
      :ids="(selected || []).map(v => v.id)"
      :props="{campaign_id: campaignId, createCampaignJobs: false}"
      :value="showPublish"
      @close="closePublish"
      @update="loadItems"
    />

    <Side
      component="forms/publication/compact/FormPublicationCompact"
      :ids="(selectedPublication || []).map(v => v.id)"
      :props="{campaign_id: campaignId}"
      :value="showPublicationEdit"
      @close="closePublicationEdit"
      @update="loadItems"
      @remove="removePublicationItems"
    />

    <DialogRemove
      :value="showDialogRemove"
      :items="selected"
      item-text="id"
      @confirm="confirmRemoveItems"
      @close="showDialogRemove = false"
      @select="selectItems"
    />

    <DialogRemove
      :value="showDialogPublicationRemove"
      :items="selectedPublication"
      item-text="id"
      @confirm="confirmPublicationRemoveItems"
      @close="showDialogPublicationRemove = false"
      @select="selectPublicationItems"
    />

    <List
      :items="items"
      :count="count"
      :offset="offset"
      :limit="limit"
      :pagination="true"
      :loading="loading"
      :selected="selected"
      @applyFilters="applyFilters"
      @resetFilters="resetFilters"
      @select="selectItems"
      @page="changePage"
      @click="selectItem"
      @dblclick="publishItem"
      @close="closePublish"
      @reload="searchItems"
    >
      <template v-slot:header="">
        <v-col cols="4">
          <v-text-field
            ref="where.title"
            outlined
            hide-details
            dense
            clearable
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('COMMON.SEARCH')"
            @keyup="searchItems"
            @click:clear="searchItems"
          />
        </v-col>
        <v-col cols="2" class="pl-0">
          <v-select
            ref="where.publications.status_type"
            :items="$t('PUBLICATION.STATUS_TYPE_ITEMS')"
            :label="$t('PUBLICATION.STATUS')"
            item-text="text"
            item-value="value"
            clearable
            multiple
            outlined
            hide-details
            dense
            @change="searchItems"
            @click:clear="searchItems"
          />
        </v-col>
        <v-col cols="2" class="pl-0">
          <v-select
            ref="where.feed_id"
            :items="feedItems"
            item-text="title"
            item-value="id"
            hide-details
            outlined
            dense
            clearable
            multiple
            :prepend-inner-icon="$t('FEED.ICON')"
            :label="$t('FEED.TITLE')"
            @change="searchItems"
            @click:clear="searchItems"
          />
        </v-col>
        <v-col cols="2" class="pl-0">
          <v-select
            ref="where.publications.channel_subscription_id"
            :items="channelSubscriptionItems"
            item-text="channel.title"
            item-value="id"
            hide-details
            outlined
            dense
            clearable
            multiple
            :prepend-inner-icon="$t('CHANNEL.ICON')"
            :label="$t('CHANNEL.TITLE')"
            @change="searchItems"
            @click:clear="searchItems"
          />
        </v-col>

      </template>

      <template v-slot:filters="">
        <div class="pa-4">
          <v-row>
            <v-col>
              <v-checkbox
                ref="where.parent_id"
                :label="$t('JOB.PARENT')"
                :value="null"
                hide-details
                class="mt-0"
                @change="searchItems"
              />
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col>
              <span class="text-small text-uppercase grey--text">{{ $tc("INPUT.LOCATION_CITY") }}</span>
              <v-autocomplete
                ref="where.location_city"
                :search-input.sync="locationInput"
                multiple
                outlined
                dense
                clearable
                v-model="cities"
                :items="cityItemsMerged"
                item-text="name"
                :loading="citiesIsBusy"
                return-object
                small-chips
                hide-details
                @change="searchItems"
                @click:clear="searchItems"
              >
                <template v-slot:item="{ index, item }">
                  {{item.name}}<small class="grey--text ml-2 numeric">{{item.code}}, <span class="text-uppercase">{{item.country_code}}</span></small>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col>
              <span class="text-small text-uppercase grey--text">{{ $tc("INPUT.COMPANY") }}</span>
              <v-text-field
                ref="where.company_title"
                clearable
                outlined
                dense
                hide-details
                @keyup="searchItems"
                @click:clear="searchItems"
              />
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col>
              <span class="text-small text-uppercase grey--text">{{ $tc("INPUT.OCCUPATIONS") }}</span>
              <v-combobox
                ref="where.xtramile_occupation_id"
                @click:clear="searchItems"
                @change="searchItems"
                small-chips
                clearable
                multiple
                dense
                hide-details
                :placeholder="$t('INPUT.MULTIPLE_VALUE')"
                :items="occupationItems"
                item-text="title"
                item-value="id"
                outlined
              />
            </v-col>
          </v-row>

          <v-row class="mt-0 mb-4">
            <v-col>
              <span class="text-small text-uppercase grey--text">{{ $tc("INPUT.SECTOR") }}</span>
              <v-combobox
                ref="where.xtramile_sector_id"
                @click:clear="searchItems"
                @change="searchItems"
                small-chips
                clearable
                multiple
                dense
                hide-details
                :placeholder="$tc('INPUT.SECTOR')"
                :items="sectorItems"
                item-text="title"
                item-value="id"
                outlined
              />
            </v-col>
          </v-row>

          <v-divider class="mb-4" />
          <p>{{$t('FEED.FILEFORMAT_CUSTOM_TITLE')}}</p>
          <div v-for="(cfilter, i) in customFilters" :key="'blbn'+i">
            <v-row class="mt-0">
              <v-col>
                <span class="text-small text-uppercase grey--text">{{ cfilter }}</span>
                <v-text-field
                  :ref="'where.custom.' + cfilter"
                  clearable
                  outlined
                  dense
                  hide-details
                  @keyup="searchItems"
                  @click:clear="searchItems"
                />
                <!-- <v-combobox
                  :ref="'where.custom.' + cfilter"
                  @click:clear="searchItems"
                  @change="searchItems"
                  small-chips
                  clearable
                  multiple
                  dense
                  hide-details
                  :placeholder="cfilter"
                  outlined
                /> -->
              </v-col>
            </v-row>
          </div>

        </div>
      </template>

      <template v-slot:nav="">
        <!-- <v-btn icon @click="searchItems"><v-icon>mdi-reload</v-icon></v-btn> -->
      </template>

      <template v-slot:empty="">
        <div class="ml-10 mt-6 text-center">
          <div class="text-h5 mt-10 mb-4">{{$t('LIST.NO_RESULT')}}</div>
          <template v-if="$queryFilters().count() > 0">
            <div><small class="grey--text">{{$t('LIST.SUGGESTION')}}</small></div>
            <div><a class="info--text" @click="clearFilters">{{$t('LIST.CLEAR_FILTER')}}</a></div>
            <!-- <div><a class="info--text" @click="newItem">{{$t('LIST.CREATE_ITEM')}}</a></div> -->
          </template>
        </div>
      </template>

      <template v-slot:selections="">
        <v-col cols="12" class="pt-0 pl-0">
          <div><small><strong>{{ $tc('COMMON.SELECTED', +selected.length) }}</strong></small></div>

            <v-btn
              class="mr-2"
              small
              depressed
              color="primary"
              @click="selectAllPages"
            >
              <v-icon size="16" class="mr-1">mdi-playlist-plus</v-icon>
              {{ $t('COMMON.SELECT_ALL_PAGES') }}
            </v-btn>

            <v-btn
              class="mr-2"
              small
              depressed
              color="primary"
              @click="showPublish = true"
            >
              <v-icon size="16" class="mr-1">mdi-forwardburger</v-icon>
              {{ $t('COMMON.PUBLISH') }}
            </v-btn>

            <v-btn
              class="mr-2"
              small
              depressed
              color="primary"
              @click="showEdit = true"
            >
              <v-icon size="16" class="mr-1">mdi-pencil</v-icon>
              {{ $t('COMMON.EDIT') }}
            </v-btn>

            <v-btn
              class="mr-2"
              small
              depressed
              color="error"
              @click="showDialogRemove = true"
            >
              <v-icon size="16" class="mr-1">mdi-delete</v-icon>
              {{ $t('COMMON.REMOVE') }}
            </v-btn>
            <v-btn
              class="mr-2"
              small
              depressed
              color="secondary black--text"
              @click="selectItems([])"
            >
              <v-icon size="16" class="mr-1" color="black">mdi-close</v-icon>
              {{ $t('COMMON.CANCEL') }}
            </v-btn>

        </v-col>
      </template>

      <template v-slot:item-list-children="item">
        <v-list dense class="pa-0 pl-14 mb-0 rouded overflow-hidden" color="transparent">
          <template v-for="(pub, i) in item.publications">
            <!-- <v-divider :key="'div'+i" /> -->
            <v-list-item
            :key="'pub'+i"
            dense
            link
            :class="['pl-0', pub.status_type==='error' ? 'error lighten-5' : '', pub.status_type==='info' ? 'info lighten-5' : '']"
            @dblclick.stop="editPublicationItems(pub)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon :color="pub.status_type" size="18" class="mr-6 ml-1">{{$t(pub.status_code+'_ICON')}}</v-icon>
                  <small><strong>{{pub.channel.title}}</strong></small>
                  <span :class="[pub.status_type === 'error' ? 'error--text' : 'grey--text']">
                    <small class="ml-2"><strong>{{$t(pub.status_code)}}</strong></small>
                    <small v-if="pub.status_message" class="ml-2" v-html="handleStatusMessage(pub.status_message)"></small>
                  </span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="pr-2">
                <span class="text-small text-uppercase"><small>{{pub.method}}</small></span>
              </v-list-item-action>
              <!-- <v-divider vertical /> -->
              <v-list-item-action class="price pr-2 pl-2">
                <small v-if="pub.method !== 'credit' && pub.method !== 'organic'"><strong class="number">€ {{(pub.price || 0).toFixed(2)}}</strong></small>
                <small v-else-if="pub.method === 'credit'"><strong class="number">Cr. {{pub.price}}</strong></small>
                <small v-else><strong class="number">-</strong></small>
              </v-list-item-action>
              <!-- <v-divider vertical /> -->
              <v-list-item-action class="mr-2">
                <div>
                  <v-btn
                  small
                    icon
                    class="ml-2"
                    @click.stop="removePublicationItems(pub)"
                  >
                    <v-icon size="18">mdi-delete</v-icon>
                  </v-btn>

                    <v-btn
                      small
                      icon
                      class="ml-2"
                      @click.stop="editPublicationItems(pub)"
                      >
                      <v-icon size="18">mdi-pencil</v-icon>
                    </v-btn>
                </div>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </template>


      <template v-slot:item-list="item">
        <!-- <div v-if="item.status_type" :class="['list-overlay', item.status_type ? item.status_type : '']"/> -->
          <v-list-item-content>
            <v-list-item-subtitle class="numeric text-small">
              <span class="mr-2 primary--text" v-if="item.company_title || item.company">{{item.company_title || item.company}}</span>

              <!-- <span class="mr-2" v-if="item.xtramile_sector_id"><v-icon class="mr-1" size="12">mdi-bookmark</v-icon>{{ $t('JOB.SECTOR.' + item.xtramile_sector_id) }}</span> -->
            </v-list-item-subtitle>
            <v-list-item-title class="primary--text position-relative">
              <strong :class="[item.draft ? 'grey--text' : '']">{{item.title}}</strong>
            </v-list-item-title>
            <v-list-item-subtitle class="text-small numeric">

              <div class="d-flex">
                <div v-if="item.feed_id" style="width: 140px; overflow:hidden; text-overflow:ellipsis;">
                  <v-icon class="mr-1" size="12" >mdi-map-marker</v-icon>
                  <span v-if="item.location"><strong>{{ item.location_city + ', ' + item.location_city_code }}</strong></span>
                </div>

                <div class="mr-1" v-if="item.feed_id">
                  <v-icon class="mr-1" size="12" >{{$t('FEED.ICON')}}</v-icon>
                  <span>{{item.feed.title}}</span>
                </div>

                <div class="error--text ml-1" v-if="getReportCount(item)">
                  <v-icon class="mr-1" size="12" color="error" >mdi-alert</v-icon>
                  <span>{{$tc('JOB.STATUS_ERROR_COUNT', item.publication_count_error || 0)}}</span>
                  <!-- <span>{{$tc('JOB.STATUS_ERROR_COUNT', getReportCount(item) || 10)}}</span> -->
                </div>

                <div class="ml-1" v-if="item.parent_id" >
                  <v-icon class="mr-1" size="12">{{ $t('COMMON.DUPLICATE_ICON') }}</v-icon>
                  <span>{{$t('JOB.DUPLICATED')}}</span>
                </div>

                <div class="pink--text ml-1" v-if="item.status === 0" >
                  <v-icon class="mr-1" size="12" color="pink" >mdi-archive</v-icon>
                  <span>{{$t('JOB.ARCHIVED')}}</span>
                </div>

                <div class="error--text ml-1" v-if="item.draft" >
                  <v-icon class="mr-1" size="12" color="error" >mdi-pencil</v-icon>
                  <span>{{$t('JOB.DRAFT')}}</span>
                </div>
              </div>

            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action class="ml-2">
            <status-label v-if="item.status_type" icon-size="14" :item="item" small />
            <div class="date" v-else>{{ $moment(item.updated_at).fromNow() }}</div>
          </v-list-item-action>
          <v-list-item-action>
            <div>
              <v-btn
                icon
                class="ml-2"
                @click.stop="removeItem(item)"
              >
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
              <a
                class="ml-2"
                :href="item.url"
                :title="item.url"
                target="blank"
              >
                <v-btn icon>
                  <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
              </a>
              <v-btn
                icon
                class="ml-2"
                @click.stop="editItem(item)"
                >
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
            </div>
          </v-list-item-action>

      </template>
    </List>
  </div>
</template>

<script>
import Side from '@/components/Side'
import DialogRemove from '@/components/DialogRemove'
import List from '@/components/List'
import StatusLabel from '@/components/StatusLabel'

const translate = {

}


export default {
  name: 'PageXprogrammaticAllCampaignJobs',
  components: {
    Side,
    DialogRemove,
    List,
    StatusLabel,
  },
  data: (_this) => ({
    statusItems: _this.$i18n.t('COMMON.STATUS_ITEMS'),
    loading: false,
    locationInput: '',
    locationSearchTimeout: 0,
    locationLoading: false,
    sectorItems: [],
    occupationItems: [],
    items: [],
    count: 0,
    offset: 0,
    limit: 20,
    page: 1,
    selected: [],
    selectedPublication: [],
    showPublish: false,
    showNew: false,
    showEdit: false,
    showPublicationEdit: false,
    showDialogRemove: false,
    showDialogPublicationRemove: false,
    showDialogPermissionEdit: false,
    timeout: null,
    status: [1],
    methods: [],
    startOfDay: new Date(`${new Date().toISOString().substr(0,10)}T00:00:00.000Z`),
    endOfDay: new Date(`${new Date().toISOString().substr(0,10)}T23:59:59.999Z`),
    countryItems: [],
    feedItems: [],
    controllerNames: [],
    channelSubscriptionItems: [],
    campaign: {},
    cities: [],
    cityItems: [],
    citiesIsBusy: false,
    // filter settings
    publicationStatusType: undefined,
    customFilters: [],
  }),
  props: ['update', 'select'],

  created() {
    this.loadOccupationsItems()
    this.loadSectorItems()
  },

  watch: {
    update () {
      this.loadItems()
    },
    select (items) {
      this.selected = items
    },
    locationInput (n) {
      this.searchLocationItems(n)
    },
  },

  mounted () {
    this.socket = this.$services.createSocket()
    this.socket.on('notification', (data) => {
      if (data.scope === "campaign" && this.campaignId && data.element_id === this.campaignId) {
        this.searchItems()
      }
    })

    this.$queryFilters().get()
    this.loadFeedItems()
    this.loadCampaign()
    this.loadCountryItems()
    this.searchItems()
    if (this.itemId) {
      this.editItem({id: this.itemId})
    }
    if (this.publishId) {
      this.publishItem({id: this.publishId})
    }
  },
  beforeDestroy() {
    this.$services.destroySocket(this.socket)
  },

  computed: {
    cityItemsMerged () {
      return this.cityItems.concat(this.cities)
    },
    publishId () {
      return this.$router.currentRoute.query.publish || undefined
    },
    itemId () {
      return this.$router.currentRoute.query.edit || undefined
    },
    campaignId () {
      return this.$router.currentRoute.params.id || undefined
    },
  },

  methods: {

    handleStatusMessage(message) {
      if (message && message.trim()) {
        let m = message
        m = m.replace('This required fields may be missed', 'Champs manquants')
        m = m.replace(/,/gmi, ', ')
        m = m.replace(/\./gmi, ' -> ')
        m = m.replace(/job/gmi, 'offre')
        m = m.replace(/experience/gmi, 'experience')
        m = m.replace(/location/gmi, 'localisation')
        m = m.replace(/city/gmi, 'ville')
        m = m.replace(/department/gmi, 'departement')
        m = m.replace(/region/gmi, 'region')
        m = m.replace(/country/gmi, 'pays')
        m = m.replace(/salary/gmi, 'salaire')
        m = m.replace(/code/gmi, '')
        m = m.replace(/_/gmi, ' ')
        return m
      }
      return message
    },

    clearFilters () {
      this.$queryFilters().clear()
      this.loadItems()
    },

    showChildrenList (item) {
      for (let i = 0; i < this.items.length; i++) {
        console.log(item.id === this.items[i].id)
        if (item.id === this.items[i].id) {
          this.items[i].showChildren = true
          break
        }
      }
    },

    getReportCount (item) {
      const report = item.status_report
      if (report) return Object.keys(report).filter(k => {
        return typeof report[k] === 'object' && this.controllerNames.indexOf(k) !== -1
      }).length
      return 0
    },

    onCitySearch (searchCitiesInput) {
      this.searchCitiesInput = searchCitiesInput;
    },

    onCountrySearch (searchCountriesInput) {
      this.searchCountriesInput = searchCountriesInput;
    },

    onLocationChange (value, propName) {
      this.where[propName] = value;
    },

    formatLocation (str) {
      return (str || '').split(',').slice(0,2).join(',')
    },

    getFeedItem (feed_id, field) {
      for (let i = 0, len = this.feedItems.length; i < len; i++) {
        const c = this.feedItems[i]
        if (c.id === feed_id) return (field ? c[field] : c)
      }
      return '?'
    },

    loadFeedItems () {
      this.$services.api_programmatic.feed.search({where: { status: [1] }})
        .then(response => {
          this.feedItems = response.data
          const filters = []
          this.feedItems.map(item => {
            if (item.fileformat_fields && item.fileformat_fields.length > 0) {
              item.fileformat_fields.map(field => {
                if (field.custom) {
                  if ((filters).indexOf(field.key) === -1) {
                    filters.push(field.key)
                  }
                }
              })
            }
          })
          this.customFilters = filters
        })
    },

    loadCampaign () {
      this.$services.api_programmatic.campaign.search({where: { id: this.campaignId }})
        .then(response => {
          this.campaign = response.data[0]
          this.$services.api_programmatic.channel_subscription.search({where: { method: this.campaign.methods, status: [1], channel: true }})
            .then(response => {
              this.channelSubscriptionItems = response.data
              this.controllerNames =  this.channelSubscriptionItems.map(v => v.channel.controller_name)
            })
        })
    },

    loadCountryItems () {
      this.$services.api_programmatic.location_country.search({where: { status: [1] }})
        .then(response => {
          const mapData = ({ name: text, code: value }) => ({ text, value })
          this.countryItems = response.data.map(mapData)
        })
    },

    loadSectorItems() {
      this.$services.api_programmatic.job_sector.search()
        .then(response => {
          this.sectorItems = response.data
        })
    },

    loadOccupationsItems() {
      this.$services.api_programmatic.job_occupation.search()
        .then(response => {
          this.occupationItems = response.data
        })
    },

    // Request

    removeItems () {
      this.showEdit = false
      this.$router.push('/xprogrammatic/campaign/' + this.campaignId + '/jobs/')
      this.loadItems()
    },

    searchItems (element) {
      this.selected = []
      this.loading = true
      // debounce request
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.loadItems()
      }, 100)
    },

    loadItems (options = {}) {
      this.loading = true
      this.$queryFilters().save()
      const defaultOptions = {
        order: [['updated_at', 'DESC']],
        mode: 'list',
        page: this.page || 1,
        limit: this.limit,
      }

      const query = this.$queryFormat({
        where: {
          status: 1,
          publication_count: {
            campaign_id: this.campaignId,
            status: [1],
          },
          publications: {
            status: [1],
            campaign_id: this.campaignId,
          },
          campaign_jobs: {
            campaign_id: this.campaignId,
            required: true,
          },
        },
        options: Object.assign({}, defaultOptions, {options}),
      })

      if (query.where.location_city) {
        query.where.location = this.cities.map(item => item.name)
        query.where.location_city = this.cities.map(item => item.name)
        query.where.location_city_code = this.cities.map(item => item.code)
      }
      if (query.where.xtramile_sector_id && typeof query.where.xtramile_sector_id === 'object') {
        query.where.xtramile_sector_id = query.where.xtramile_sector_id.map(v => v.id)
      }
      if (query.where.xtramile_occupation_id && typeof query.where.xtramile_occupation_id === 'object') {
        query.where.xtramile_occupation_id = query.where.xtramile_occupation_id.map(v => v.id)
      }

      this.$emit('childData', {queryJobList: query} )
      this.$services.api_programmatic.job.search(query)
        .then(response => {
          if (response.data) {
            const td = v => {
              v.feed = { title: this.getFeedItem(v.feed_id, 'title') }
              return v
            }
            this.items = response.data.items.map(td)
            this.count = response.data.count
            this.offset = response.data.offset
            this.limit = response.data.limit
          } else {
            this.items = []
            this.count = 0
            this.offset = 0
            this.limit = 0
          }
          this.updateSelected()
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
        .finally(() => {
          this.loading = false
        })
    },

    changePage (page) {
      this.page = +page
      this.searchItems()
    },

    confirmRemoveItems () {
      const ids = this.selected.map(v => v.id)
      this.$services.api_programmatic.campaign_job.remove({ where: { job_id: ids }, options: {limit:ids.length}})
        .then(response => {
          this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
          this.selected = []
          this.showDialogRemove = false
          this.loadItems()
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
    },

    confirmPublicationRemoveItems () {
      const ids = this.selectedPublication.map(v => v.id)
      this.$services.api_programmatic.publication.remove({ where: { id: ids }, options: {limit:ids.length}})
        .then(response => {
          this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
          this.selectedPublication = []
          this.showPublicationDialogRemove = false
          this.loadItems()
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
    },

    updateSelected () {
      for (let i = 0, lenI = this.selected.length; i < lenI; i++) {
        const a = this.selected[i]
        for (let j = 0, lenJ = this.items.length; j < lenJ; j++) {
          const b = this.items[j]
          if (a.id === b.id) {
            this.selected[i] = b
            break
          }
        }
      }
    },

    // Mutliple items

    removeSelected () {
      this.showDialogRemove = true
    },

    editSelected () {
      this.showEdit = true
      if (this.selected.length > 0) {
        const item = this.selected[0]
        this.$router.push(`/xprogrammatic/campaign/${this.campaignId}/jobs/?edit=${item.id}`)
      }
    },

    selectItem (item) {
      for (let i=0, len = this.selected.length; i < len; i++) {
        if (this.selected[i].id === item.id) return this.selected.splice(i,1)
      }
      return this.selected.push(item)
    },

    selectItems (arr) {
      this.selected = arr
    },

    selectPublicationItems (arr) {
      this.selectedPublication = arr
    },

    closePublicationEdit () {
      this.showPublicationEdit = false
    },

    closeEdit () {
      this.showEdit = false
      this.$router.push(`/xprogrammatic/campaign/${this.campaignId}/jobs/`)
    },

    closePublish () {
      this.showPublish = false
      this.$router.push(`/xprogrammatic/campaign/${this.campaignId}/jobs/`)
    },


    selectAllPages (options) {
      this.loading = true
      const defaultOptions = {
        order: [['updated_at', 'DESC']],
        attributes: ['id', 'updated_at'],
        limit: 100000,
      }
      const query = this.$queryFormat({
        where: {
          status: 1,
          campaign_jobs: {
            attributes: ['id', 'campaign_id'],
            campaign_id: this.campaignId,
            required: true
          }
        },
        options: Object.assign({}, defaultOptions, {options}),
      })

      this.$services.api_programmatic.job
        .search(query)
        .then(response => {
          if (response.data) {
            this.selected = response.data
          }
        })
        .finally(() => {
          this.loading = false
        })

    },

    // Single item

    updateItems() {
      this.loadItems()
    },

    newItem () {
      this.selected = []
      this.showNew = true
    },

    editItem (item) {
      this.selected = [item]
      this.$router.push(`/xprogrammatic/campaign/${this.campaignId}/jobs/?edit=${item.id}`)
      this.showEdit = true
    },

    editPublicationItems (item) {
      this.selectedPublication = [item]
      this.showPublicationEdit = true
    },

    publishItem (item) {
      this.selected = [item]
      this.$router.push(`/xprogrammatic/campaign/${this.campaignId}/jobs/?publish=${item.id}`)
      this.showPublish = true
    },

    editPermissionItem (item) {
      this.selected = [item]
      this.showDialogPermissionEdit = true
    },

    removePublicationItems (item) {
      this.selectedPublication = [item]
      this.showDialogPublicationRemove = true
    },

    removeItem (item) {
      this.selected = [item]
      this.showDialogRemove = true
    },

    goToItem (item) {
      // item = item ? item : this.selected[0]
      // const route = `/job/${  item.id}`
      // if (this.$router.currentRoute.path !== route) {
      //   this.$router.push(route)
      // }
    },

    applyFilters () {
      this.searchItems()
    },

    resetFilters () {
      this.applyFilters()
    },

    searchLocationItems (n) {
      const then = (response) => {
        let data = []
        if (response && response.data) data = response.data
        this.cityItems = data
      }

      if (this.locationSearchTimeout) clearTimeout(this.locationSearchTimeout)
      this.locationLoading = true
      this.locationSearchTimeout = setTimeout(() => {
        if (n) {
          this.$services.api_programmatic
            .location_city.search({where:{title:n}, options: {limit: 10}})
            .then(then)
        }else {
          Promise.resolve().then(then)
        }
      }, 200)
    },

    // async loadOccupations () {
    //   this.loading = true
    //   const response = await this.$services.api_programmatic.job_occupation.search()
    //   this.occupations = response.data
    // },
  }
}
</script>
